@use 'variables' as *;
@use 'mixins' as *;

.hero {
    @include breakpoint-up (large) {
        display:flex;
        align-items: center;
    }

    &__image {
       background-image: url('/assets/images/image-hero-mobile.png');
       background-size: contain;
       width: 100%;
       height: 18.75rem;
       background-repeat: no-repeat;
       background-position: center center;
       margin-bottom: 2rem;

       @include breakpoint-up (large) {
           background-image: url('/assets/images/image-hero-desktop.png');
           position: relative;
           flex: 1;
           order: 2;
           height: 40rem;
           padding-right: 8.25rem;
       }

    }

    &__text {
        position: absolute;
        text-align: center;
        z-index: -1;

        @include breakpoint-up (large) {
            position: relative;
            flex: 1;
            order: 1;
            z-index: 1;
            margin-right: 9.4rem;
            text-align: left;
            padding-left: 10.6rem;
        }

        h1{
            font-size: 2rem;
            color: $kindaBlack;
            line-height: 1;

            @include breakpoint-up (large) {
                font-size: 4.2rem;
            }
        }

        p{
            padding-left: 1rem;
            padding-right: 1rem;
            margin-bottom: 2.5rem;
        }
    }

    &__button{
        border: solid;
        background-color: black;
        padding: 1rem 1.5rem;
        border-radius: 15px;
        border-width: 1px;
        color: white;
        transition: color 200ms ease-in-out;

        &:hover {
           background-color: white;
           color: black;
            font-weight: 700;
        }
    }
}

