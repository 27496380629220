@use 'variables' as *;
@use 'mixins' as *;






.header{
    //Hamburger open styles

    &.open {

        .header__toggle{
            >span:first-child{
                transform: rotate(45deg);
            }
            >span:nth-child(2){
                opacity: 0;
            }
            >span:last-child{
                transform: rotate(-45deg);
            }
        }

    }




    .overlay{
        visibility: hidden;
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        width: 35%;
        background: $kindaBlack;
        opacity: 0.8;

        @include breakpoint-up (large) {
            display:none;
        }
    }
    nav {
        padding: 24px 15px 24px 15px;
    }

    &__logo{

        img{
            
            width: 5.5625rem;
            height: 1.9375rem; 
            margin-left: 2.5rem;
        }
    }

    &__toggle{//Mobile Menu Toggle
        >span{
            display: block;
            width: 33px;
            height: 3px;
            background-color: $mediumGray;
            transition: all 300ms ease-in-out;
            transform-origin: 5px;

            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }


    }

    &__menu{
        display: block;
        background: $almostWhite;
        float: right;
        width: 65%;
        height: 100vh;
        padding: 1.875rem;
        top: 0px;
        bottom: 0px;

        @include breakpoint-up(large) {
            display: none;
        }


        a {
            color: $mediumGray;
            display: block;
            padding: 0.625rem;
        }
    }



    &__links{

        position: relative;

        ul{
            list-style:none;
            padding:0;
        }

        a {
            position:relative;
            font-size: $font-small;

            transition: color 200ms ease-in-out;

            &:not(:last-child) {
                margin-right: 32px;
            }

            &:hover {
                color: $kindaBlack;
                font-weight: 700;
            }
        }
    }



    .cta {
        font-size: $font-small;

        a{
            margin-right: 40px;
            transition: color 300ms ease-in-out;

            &:hover {
                color: $kindaBlack;
                font-weight: 700;
            }
        }

    }

}

.ctaMobile {
    position: relative;
    text-align: center;

    a {
        margin-bottom: 1rem;
    }


    &.button{
        display: inline;
        padding: 13px 69px

    }

}
