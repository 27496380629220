@use 'variables' as *;
@use 'mixins' as *;

html {
  font-size: 100%;
  box-sizing: border-box;
  }

*, *::before, *::after{
  box-sizing: inherit;
}



body {
  margin: 0;
  padding: 0;
  font-family: "Epilogue", sans-serif;
  font-size: $font-medium;
  font-weight: 500;
  line-height: 1.3;
  color: $mediumGray;
  background-color: $almostWhite;

  &.noscroll {
    overflow: hidden;
  }

  a, a:visited, a:hover {
    text-decoration: none;
  }

}



// Spacing
.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

//flexbox styles
.flex {
  display: flex;

  &-jc-sb{
    justify-content: space-between;
  }

  &-jc-c{
    justify-content: center;
  }

  &-ai-c{
    align-items: center;
  }
}

//Buttons (14px top, 22px left/right)

button, .button {

  padding: 0.875rem 1.375rem;
  border-radius: 15px;
  cursor: pointer;
  background-color: $almostWhite; 
  border: 2px solid $mediumGray;
  font-weight: 500;
  font-family: "Epilogue";

}

//Visibility 
.hide-for-mobile {
  @include breakpoint-down(medium) {
    display: none;
  }

  //hide for mobile

}

.hide-for-desktop{
  //hide for desktop
  @include breakpoint-up(large) {
    display:none;
  }
}